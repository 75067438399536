import React from "react";
import "./styles.scss";

import logo from "../../../images/logo.png";

function AdmissionLandingPageSuccess() {
  return (
    <main className="admissionleads-success">
      <nav>
        <a className="back" href="/">
          <i className="fa-solid fa-arrow-left"></i> Back to Home
        </a>
        <a href="/" className="admissionleads-success__logo">
          <img
            className="admissionleads-success__logo__img"
            alt="Chinmaya Logo"
            src={logo}
          ></img>
          <div className="admissionleads-success__logo__text">
            Chinmaya Vidyalaya
            <br />
            Vasant Vihar, New Delhi
          </div>
        </a>
      </nav>

      <div className="admissionleads-success__container">
        <div className="admissionleads-success__text">
          Your enquiry has been submitted successfully. A team will be in touch
          shortly
          <br />
          <a href="/">
            <i className="fa-solid fa-arrow-left"></i> Back to Home
          </a>
        </div>
      </div>
    </main>
  );
}

export default AdmissionLandingPageSuccess;
